import { withLeadingSlash, parseURL } from 'ufo';

const unlocalizedUri = () => {
    const { activeSite } = useSharedState();
    const route = useRoute();
    const pathDefault = route.path ?? '/';
    return pathDefault.replace(new RegExp(`^/${activeSite.value.prefix}`), '').replace(/^\//, '');
};

const fixedUri = (str = '/') => {
    if (typeof str !== 'string') return str;

    const parsed = parseURL(str);

    let res = withLeadingSlash(parsed.pathname);
    if (parsed.hash !== '') {
        res = {
            path: parsed.pathname.replace(/^\/?([^/]+(?:\/[^/]+)*)\/?$/, '/$1/'),
            hash: parsed.hash,
        };
    } else {
        res = parsed.pathname.replace(/^\/?([^/]+(?:\/[^/]+)*)\/?$/, '/$1');
    }

    if (res === '') {
        res = '/';
    }

    return res;
};

export { unlocalizedUri, fixedUri };
